import * as React from 'react'

import Layout from '../components/layout'

const Agenda = () => {
    return (
        <Layout>
            Agenda
        </Layout>
    )
}

export default Agenda